/**
 * IMPORTANT!!!!!!!!!
 * When updating this file you need to make sure the API Platform file to reflect this file very accurately.
 * You can find the file at "/api/src/Constants/Permissions.php
 */

// Billing
export const CREATE_SUPERBILL = 'create_superbill';
export const ACCEPT_PAYMENTS = 'accept_payments';
export const DELETE_CLAIMS = 'delete_claims';
export const MANAGE_HEALTH_CARE_SERVICES = 'manage_health_care_services';
export const MANAGE_FINANCIAL_CLASSES = 'manage_financial_classes';
export const MANAGE_PAYERS = 'manage_payers';
export const MANAGE_ELECTRONIC_CLAIM_PREFERENCES = 'manage_electronic_claim_preferences';

// Account Settings
export const MANAGE_ACCOUNT = 'manage_account';
export const MANAGE_AUTONOTES = 'manage_autonotes';
export const MANAGE_TEMPLATES = 'manage_templates';
export const MANAGE_PROGRAMS = 'manage_programs';
export const MANAGE_USERS = 'manage_users';
export const MANAGE_ROLES = 'manage_roles';
export const MANAGE_WORKBOARDS = 'manage_workboards';

// Marketing/Admissions
// export const INQUIRY_CREATE_EDIT_ARCHIVE_CLOSE = 'inquiry_create_edit_archive_close';
// export const INQUIRY_DELETE = 'inquiry_delete';

// Contact
export const MANAGE_ENCOUNTERS = 'manage_encounters';
export const MANAGE_DOCUMENTATION = 'manage_documentation';
export const ADD_CONTACTS = 'contact_add';
export const EDIT_CONTACTS = 'contact_edit';
export const DELETE_CONTACTS = 'contact_delete';
export const MANAGE_CONTACTS_EOC_PROGRAMS = 'contact_manage_eoc_programs';
export const MANAGE_INSURANCE_COVERAGE = 'contact_manage_ins_coverage';
export const REQUEST_ELIGIBILITY_CHECK = 'request_eligibility_check';
export const MANAGE_PATIENT_PORTAL_LINK = 'contact_manage_patient_portal_link';
export const UNLOCK_NOTES = 'contact_unlock_notes';
export const MANAGE_FILES = 'contact_manage_files';
export const DELETE_FILES = 'contact_delete_files';
export const VIEW_MED_LIST = 'view_med_list';
export const UPDATE_MED_LIST = 'update_med_list';
// Diagnosis
export const VIEW_DIAGNOSIS = 'view_diagnosis';
export const MANAGE_DIAGNOSIS = 'manage_diagnosis';
export const VIEW_PROBLEM_TREATMENT_PLAN = 'view_problem_treatment_plan';
export const MANAGE_PROBLEM_TREATMENT_PLAN = 'manage_problem_treatment_plan';
export const DSM5_ACCESS = 'dsm5_access';
export const ASAM_CRITERIA_ACCESS = 'asam_criteria_access';
export const VIEW_ALLERGIES = 'view_allergies';
export const MANAGE_ALLERGIES = 'manage_allergies';
export const MANAGE_TREAMENT_GROUPS = 'manage_treatment_groups';
