// EpisodeOfCare Status Mapping Constants
// ----------------------------------
// Constants for status mapping
export const STATUS_DISPLAY = {
    ELIGIBILITY_NOT_DETERMINED: 'Eligibility not determined',
    NOT_ELIGIBLE: 'Not eligible',
    PLANNED: 'Planned (approved)',
    CANCELLED: 'Cancelled',
    WAITLIST: 'Waitlist (approved)',
    ACTIVE: 'Active',
    ON_HOLD: 'On hold',
    FINISHED: 'Discharged',
    ENTERED_IN_ERROR: 'Entered in error',
};

// UI display to FHIR status mapping
export const UI_TO_FHIR_STATUS = {
    [STATUS_DISPLAY.ELIGIBILITY_NOT_DETERMINED]: 'planned', // Special case - sets eligibilityStatus too
    [STATUS_DISPLAY.NOT_ELIGIBLE]: 'cancelled', // Special case - sets eligibilityStatus too
    [STATUS_DISPLAY.PLANNED]: 'planned', // Regular planned status
    [STATUS_DISPLAY.CANCELLED]: 'cancelled', // Regular cancelled status
    [STATUS_DISPLAY.WAITLIST]: 'waitlist',
    [STATUS_DISPLAY.ACTIVE]: 'active',
    [STATUS_DISPLAY.ON_HOLD]: 'onhold',
    [STATUS_DISPLAY.FINISHED]: 'finished',
    [STATUS_DISPLAY.ENTERED_IN_ERROR]: 'entered-in-error',
};

// FHIR status to UI display mapping
export const FHIR_TO_UI_STATUS = {
    planned: STATUS_DISPLAY.PLANNED, // Default mapping, might be overridden by eligibilityStatus
    cancelled: STATUS_DISPLAY.CANCELLED, // Default mapping, might be overridden by eligibilityStatus
    waitlist: STATUS_DISPLAY.WAITLIST,
    active: STATUS_DISPLAY.ACTIVE,
    onhold: STATUS_DISPLAY.ON_HOLD,
    finished: STATUS_DISPLAY.FINISHED,
    'entered-in-error': STATUS_DISPLAY.ENTERED_IN_ERROR,
};

// UI display to custom Eligibility Status mapping
export const UI_TO_ELIGIBILITY_STATUS = {
    [STATUS_DISPLAY.ELIGIBILITY_NOT_DETERMINED]: 'TBD',
    [STATUS_DISPLAY.NOT_ELIGIBLE]: 'NotEligible',
    [STATUS_DISPLAY.PLANNED]: null, // No eligibility status for regular planned
    [STATUS_DISPLAY.CANCELLED]: null, // No eligibility status for regular cancelled
    [STATUS_DISPLAY.WAITLIST]: null,
    [STATUS_DISPLAY.ACTIVE]: null,
    [STATUS_DISPLAY.ON_HOLD]: null,
    [STATUS_DISPLAY.FINISHED]: null,
    [STATUS_DISPLAY.ENTERED_IN_ERROR]: null,
};

// ----------------------------------

// Encounter (Program) Status Mapping Constants
// ----------------------------------
// Constants for Encounter(Program) status mapping

// Episode of Care status to Encounter status
export const EOCStatusToEncounterStatus = {
    planned: { status: 'planned', eligibilityStatus: '' },
    cancelled: { status: 'cancelled', eligibilityStatus: '' },
    waitlist: { status: 'triaged', eligibilityStatus: '' },
    active: { status: 'in-progress', eligibilityStatus: '' },
    onhold: { status: 'onleave', eligibilityStatus: '' },
    finished: { status: 'finished', eligibilityStatus: '' },
    'entered-in-error': { status: 'entered-in-error', eligibilityStatus: '' },
    NotEligible: { status: 'cancelled', eligibilityStatus: 'NotEligible' },
    TBD: { status: 'planned', eligibilityStatus: 'TBD' },
};

export const ENCOUNTER_STATUS_DISPLAY = {
    ELIGIBILITY_NOT_DETERMINED: 'Eligibility not determined',
    NOT_ELIGIBLE: 'Not eligible',
    PLANNED: 'Planned (approved)',
    CANCELLED: 'Cancelled',
    WAITLIST: 'Waitlist (approved)',
    ACTIVE: 'Active',
    ON_HOLD: 'On hold',
    FINISHED: 'Discharged',
    ENTERED_IN_ERROR: 'Entered in error',
};
export const PROGRAM_STATUS_DISPLAY = JSON.parse(JSON.stringify(ENCOUNTER_STATUS_DISPLAY));

export const ENCOUNTER_STATUS_DISPLAY_SHORT = {
    ELIGIBILITY_NOT_DETERMINED: 'Eligibility TBD',
    NOT_ELIGIBLE: 'Not eligible',
    PLANNED: 'Planned',
    CANCELLED: 'Cancelled',
    WAITLIST: 'Waitlist',
    ACTIVE: 'Active',
    ON_HOLD: 'On hold',
    FINISHED: 'Discharged',
    ENTERED_IN_ERROR: 'Entered in error',
};
export const PROGRAM_STATUS_DISPLAY_SHORT = JSON.parse(JSON.stringify(ENCOUNTER_STATUS_DISPLAY_SHORT));

// Encounter(Program) UI display to FHIR status mapping
export const ENCOUNTER_UI_TO_FHIR_STATUS = {
    [ENCOUNTER_STATUS_DISPLAY.ELIGIBILITY_NOT_DETERMINED]: 'planned', // Special case - sets eligibilityStatus too
    [ENCOUNTER_STATUS_DISPLAY.NOT_ELIGIBLE]: 'cancelled', // Special case - sets eligibilityStatus too
    [ENCOUNTER_STATUS_DISPLAY.PLANNED]: 'planned', // Regular planned status
    [ENCOUNTER_STATUS_DISPLAY.CANCELLED]: 'cancelled', // Regular cancelled status
    [ENCOUNTER_STATUS_DISPLAY.WAITLIST]: 'triaged',
    [ENCOUNTER_STATUS_DISPLAY.ACTIVE]: 'in-progress',
    [ENCOUNTER_STATUS_DISPLAY.ON_HOLD]: 'onleave',
    [ENCOUNTER_STATUS_DISPLAY.FINISHED]: 'finished',
    [ENCOUNTER_STATUS_DISPLAY.ENTERED_IN_ERROR]: 'entered-in-error',
};
export const PROGRAM_UI_TO_FHIR_STATUS = JSON.parse(JSON.stringify(ENCOUNTER_UI_TO_FHIR_STATUS));

// Encounter(Program) FHIR status to UI display mapping
export const ENCOUNTER_FHIR_TO_UI_STATUS = {
    planned: ENCOUNTER_STATUS_DISPLAY.PLANNED, // Default mapping, might be overridden by eligibilityStatus
    cancelled: ENCOUNTER_STATUS_DISPLAY.CANCELLED, // Default mapping, might be overridden by eligibilityStatus
    triaged: ENCOUNTER_STATUS_DISPLAY.WAITLIST,
    'in-progress': ENCOUNTER_STATUS_DISPLAY.ACTIVE,
    onleave: ENCOUNTER_STATUS_DISPLAY.ON_HOLD,
    finished: ENCOUNTER_STATUS_DISPLAY.FINISHED,
    'entered-in-error': ENCOUNTER_STATUS_DISPLAY.ENTERED_IN_ERROR,
};
export const PROGRAM_FHIR_TO_UI_STATUS = JSON.parse(JSON.stringify(ENCOUNTER_FHIR_TO_UI_STATUS));

// Encounter (Program) UI display to custom Eligibility Status mapping
export const ENCOUNTER_UI_TO_ELIGIBILITY_STATUS = {
    [ENCOUNTER_STATUS_DISPLAY.ELIGIBILITY_NOT_DETERMINED]: 'TBD',
    [ENCOUNTER_STATUS_DISPLAY.NOT_ELIGIBLE]: 'NotEligible',
    [ENCOUNTER_STATUS_DISPLAY.PLANNED]: null, // No eligibility status for regular planned
    [ENCOUNTER_STATUS_DISPLAY.CANCELLED]: null, // No eligibility status for regular cancelled
    [ENCOUNTER_STATUS_DISPLAY.WAITLIST]: null,
    [ENCOUNTER_STATUS_DISPLAY.ACTIVE]: null,
    [ENCOUNTER_STATUS_DISPLAY.ON_HOLD]: null,
    [ENCOUNTER_STATUS_DISPLAY.FINISHED]: null,
    [ENCOUNTER_STATUS_DISPLAY.ENTERED_IN_ERROR]: null,
};
export const PROGRAM_UI_TO_ELIGIBILITY_STATUS = JSON.parse(JSON.stringify(ENCOUNTER_UI_TO_ELIGIBILITY_STATUS));

// ----------------------------------

export const STATUS_DISPLAY_MAP = {
    planned: 'Eligibility not determined',
    cancelled: 'Not eligible',
    active: 'Active',
    onhold: 'On hold',
    finished: 'Finished',
    'entered-in-error': 'Entered in error',
    waitlist: 'Waitlist',
};
