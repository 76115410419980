import { startCase, isEmpty } from 'lodash';
import { FHIRObjectBase } from '@/fhirworks';

const bnErrorTemplates = {
    messages: {
        alphaNum: 'Only letters and numbers are allowed',
        diagnosisPointerMaxSelection: 'Maximum of 4 can be selected',
        sbModifierDuplicateCheck: 'Procedure code with modifier already exist',
        email: 'Invalid email',
        duplicateEmail: 'Email already in use',
        existingValueFound: '{attribute} already exist',
        dateMustBeGreaterThanStartDate: 'Date must be greater than start date',
        greaterThanZero: 'Minimum value 1',
        integer: 'Must be an integer',
        invalidPhoneFax: 'Invalid Phone/Fax',
        maxLength: '{attribute} has a maximum length of {max}',
        maxValue: '{attribute} has a maximum value of {max}',
        minLength: '{attribute} has a minimum length of {min}',
        npiLength: 'NPI must be 10 digits',
        einLength: 'EIN must be 9 digits',
        ssnLength: 'SSN must be 9 digits',
        minValue: '{attribute} has a minimum value of {min}',
        mustBeUniqueSecurityGroupName: 'Name must be unique. Name used in active or archived group.',
        overDistribute: 'Over distributed',
        required: '{attribute} is required',
        sameAsPassword: 'Passwords must be the same',
        uniqueCoverageRequired: 'Coverage with this payer and subscriber already exist',
        uniqueIdentifiersRequired: 'Identifiers must be Unique',
        uniqueValueRequired: 'Unique value required',
        onlyOneDefaultAllowed: 'Only one default value per position allowed',
        uniqueModifierCodeNPosition: 'Unique code and position values required',
        uniqueInsurancePriorityRequired: 'Insurance Priority with Effective Date already Exist',
        uniqueFinancialClassName: 'Financial Class name must be unique',
        uniqueEOCProgramRequired: 'Program is currently active for this episode of care',
        notDash: 'Cannot be dash (-)',
        eocStatusFinishedCancelValidation: 'All programs need to be resolved',
    },
    attributes: {
        firstName: 'First name',
        lastName: 'Last name',
        healthcareServiceProgramName: 'Program Name',
        newPassword: 'New Password',
        referringProviderReferenceIdentification: 'Reference Identification',
        facilityLocationReferenceIdentification: 'Facility Identification',
        billingProviderReferenceIdentification: 'Billing Identification',
        renderingProviderReferenceIdentification: 'Rendering Identification',
    },
};

const generateError = (attributeName, validationType, params) => {
    let message = 'Field is invalid';

    // If no custom error message template is found, abort
    if (!bnErrorTemplates.messages[validationType.toString()]) {
        return message;
    }

    // Replace attribute name is base message
    message = bnErrorTemplates.messages[validationType.toString()].replace('{attribute}', attributeName)?.replace('{min}', params?.min)?.replace('{max}', params?.max);

    // if no parameters to process for substitution, return
    if (!params || !params[validationType.toString()]) return message;

    // Parameters found, process each one looking for value to insert into message
    for (const paramKey in params[validationType.toString()]) {
        // ignore the type key
        if (paramKey === 'type') continue;
        message = message.replace('{' + paramKey + '}', params[validationType.toString()][paramKey.toString()]);
    }

    return message;
};

const bnVuelidateErrorExtractorPlugin = {
    install(app) {
        app.config.globalProperties.$bnVuelidateErrorExtractor = (validations, dataProperty, returnBindObj = false) => {
            const errors = [];
            let dataPropertyValidations = validations?.[dataProperty.toString()];

            // Passed property not found in the validations, return
            if (!validations?.[dataProperty.toString()]) {
                if (returnBindObj) {
                    return {};
                } else {
                    return errors;
                }
            }

            // If returning a bind object, return class bn-required-field
            // if the object is required, not dirty and the model property is empty
            if (returnBindObj && Object.prototype.hasOwnProperty.call(dataPropertyValidations, 'required') && !dataPropertyValidations.$dirty && !dataPropertyValidations.$model) {
                // If required is a function (requiredIf) execute that code to determine if required
                if (dataPropertyValidations.required.$params?.type === 'requiredIf') {
                    return dataPropertyValidations.required.$params.prop(validations.$model) ? { class: 'bn-required-field' } : {};
                }
                return { class: 'bn-required-field' };
            }

            // Check required for dirty FHIRWorks objects because they actually could be an
            // empty object and would not return required if toJSON is not evaluated for check.
            if (
                returnBindObj &&
                Object.prototype.hasOwnProperty.call(dataPropertyValidations, 'required') &&
                dataPropertyValidations.$dirty &&
                dataPropertyValidations.$model &&
                dataPropertyValidations.$model instanceof FHIRObjectBase &&
                isEmpty(dataPropertyValidations.$model.toJSON())
            ) {
                dataPropertyValidations.$model = undefined;
                return { class: 'bn-required-field' };
            }

            if (!dataPropertyValidations.$dirty) {
                if (returnBindObj) {
                    return {};
                } else {
                    return errors;
                }
            }

            let attributeName = bnErrorTemplates.attributes[dataProperty.toString()] ? bnErrorTemplates.attributes[dataProperty.toString()] : startCase(dataProperty);

            // Property is required but not passing validation
            if (returnBindObj && Object.prototype.hasOwnProperty.call(dataPropertyValidations, 'required') && dataPropertyValidations.required.$invalid) {
                return { class: 'bn-required-field' };
            }

            // Loop through all validations for the passed dataProperty
            for (const validationProp in dataPropertyValidations) {
                // Handle validations that use $each
                if (validationProp === '$each') {
                    for (const itemIndex in dataPropertyValidations.$each.$message) {
                        const dataValidation = dataPropertyValidations.$each.$message[itemIndex];

                        for (const eachValidationProp in dataValidation) {
                            if (eachValidationProp.startsWith('$')) continue;

                            !dataValidation[eachValidationProp.toString()] && errors.push(generateError(attributeName, eachValidationProp, dataValidation.$params));
                        }
                    }
                }

                if (validationProp.startsWith('$')) continue;
                let params = dataPropertyValidations.$params || dataPropertyValidations.$errors?.[0]?.$params || dataPropertyValidations?.[validationProp.toString()]?.$params;
                dataPropertyValidations[validationProp.toString()].$invalid && errors.push(generateError(attributeName, validationProp, params));
            }

            if (returnBindObj) {
                return { errorMessages: errors };
            } else {
                return errors?.length ? errors : [dataPropertyValidations?.required?.$message];
            }
        };
    },
};

export default bnVuelidateErrorExtractorPlugin;
