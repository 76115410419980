import { ref, watch, onUnmounted } from 'vue';

import { parseISO } from 'date-fns';
import { useAuthStore } from '@/stores/auth';
import router from '@/router';
import { useRoute } from 'vue-router';
import Storage from 'vue-ls';
import VueCookies from 'vue-cookies';
import { useCookies } from '@vueuse/integrations/useCookies';
import IntervalWorker from '@/workers/intervalWorker.js?worker';
import TimeoutWorker from '@/workers/timeoutWorker.js?worker';

const lockOutCountDown = ref(null);

export function useLockout() {
    const authStore = useAuthStore();
    const route = useRoute();

    const { ls } = Storage.useStorage({
        namespace: '',
        name: 'ls',
        storage: 'local',
    });

    const cookies = useCookies();

    const changeListener = ({ name, value }) => {
        if (name === 'token') {
            if (value) {
                let isLocked = authStore.isLockedOut; // have to check before setting token
                authStore.setToken(value);

                if (isLocked) {
                    router.push(authStore.lockoutPage);
                }
            } else if (route && !['lockout', 'invalid-tab'].includes(route.name)) {
                executeLockout();
            }
        } else if (name === 'lockOutCountDown' && value) {
            if (lockoutCountdownActive.value) return;
            lockOutCountDown.value = value;
            if (!lockoutCountdownActive.value) {
                startLockoutTimer();
            }
        } else if (name === 'user' && !value) {
            router.push({ name: 'login' });
        } else if (name === 'account') {
            if (!value) {
                router.push({ name: 'login' });
            } else if (value?.id !== authStore.account.id) {
                router.push({ name: 'invalid-tab' });
            }
        }
    };

    cookies.addChangeListener(changeListener);

    const lockoutTimerWorker = new IntervalWorker();
    let lockoutIntervalActive = ref(false);

    // handle
    lockoutTimerWorker.onmessage = (e) => {
        if (e.data.type === 'check') {
            // Don't check if already paused or locked
            if (lockoutCountdownActive.value || authStore.isLockedOut) {
                return;
            }

            let timeToLockout = authStore.lockoutLastActivityTime;
            let lockoutTimeMs = authStore.lockoutTime;

            // Send the current time from worker to ensure consistency
            const currentTime = e.data.currentTime || Date.now();
            const expirationTime = timeToLockout.getTime() + lockoutTimeMs;
            const timeRemaining = expirationTime - currentTime;

            if (timeRemaining <= 0) {
                startLockoutTimer();
            }
        }
    };

    const pauseLockoutInterval = () => {
        lockoutTimerWorker.postMessage({ action: 'stop' });
        lockoutIntervalActive.value = false;
    };

    const resumeLockoutInterval = () => {
        lockoutTimerWorker.postMessage({ action: 'start', interval: 2500 });
        lockoutIntervalActive.value = true;
    };

    const countdownWorker = new IntervalWorker();

    const lockoutCountdownActive = ref(false);

    countdownWorker.onmessage = (e) => {
        if (e.data.type === 'check') {
            if (lockOutCountDown.value === null) return;
            lockOutCountDown.value -= 1;
            VueCookies.set('lockOutCountDown', lockOutCountDown.value);
            if (lockOutCountDown.value < 1) {
                lockOutCountDown.value = null;
                VueCookies.remove('lockOutCountDown');
                executeLockout();
            }
        }
    };

    const pauseLockoutCountdown = () => {
        countdownWorker.postMessage({ action: 'stop' });
        lockoutCountdownActive.value = false;
        lockOutCountDown.value = null;
        VueCookies.remove('lockOutCountDown');
    };

    const resumeLockoutCountdown = () => {
        countdownWorker.postMessage({ action: 'start' });
        lockoutCountdownActive.value = true;
    };

    const localStorageOnLastTokenRefreshTime = (lastTokenRefreshTime) => {
        authStore.setLastTokenRefreshTime(parseISO(lastTokenRefreshTime));
    };

    const localStorageOnLockoutLastActivityTime = (lockoutLastActivityTime) => {
        resetLockoutActivity(lockoutLastActivityTime);
    };

    ls.on('lastTokenRefreshTime', localStorageOnLastTokenRefreshTime);
    ls.on('lockoutLastActivityTime', localStorageOnLockoutLastActivityTime);

    const tokenRefreshWorker = new TimeoutWorker();
    const refreshTokenTimeout = 10 * 60000; // 10 mins, token expires in 15 mins, give 5 mins buffer

    tokenRefreshWorker.onmessage = async (e) => {
        if (e.data.type === 'check') {
            await authStore.refreshAuthToken();
            startTokenRefreshTimer(); // Restart the timer after refresh
        }
    };

    const startTokenRefreshTimer = () => {
        const lastRefreshTimestamp = authStore.lastTokenRefreshTime?.getTime() || Date.now();
        const nextRefreshTimestamp = lastRefreshTimestamp + refreshTokenTimeout;
        const now = Date.now();
        const timeoutDuration = Math.max(0, nextRefreshTimestamp - now);

        tokenRefreshWorker.postMessage({
            action: 'start',
            timeout: timeoutDuration,
        });
    };

    const stopTokenRefreshTimer = () => {
        tokenRefreshWorker.postMessage({ action: 'stop' });
    };

    onUnmounted(() => {
        cookies.removeChangeListener(changeListener);

        ls.off('lastTokenRefreshTime', localStorageOnLastTokenRefreshTime);
        ls.off('lockoutLastActivityTime', localStorageOnLockoutLastActivityTime);

        lockoutTimerWorker.postMessage({ action: 'stop' });
        lockoutTimerWorker.terminate();
        countdownWorker.postMessage({ action: 'stop' });
        countdownWorker.terminate();
        tokenRefreshWorker.postMessage({ action: 'stop' });
        tokenRefreshWorker.terminate();
    });

    const resetLockoutActivity = (lockoutLastActivityTime = null) => {
        if (!authStore.isAuthenticated || authStore.isLockedOut) {
            return;
        }

        pauseLockoutCountdown();

        if (lockoutLastActivityTime) {
            authStore.setLockoutLastActivityTime(parseISO(lockoutLastActivityTime));
        } else {
            authStore.resetLockoutActivityTime();
        }

        if (!lockoutIntervalActive.value) {
            resumeLockoutInterval();
        }
    };

    const startLockoutTimer = () => {
        // if not logged in do nothing
        if (!authStore.isAuthenticated || authStore.isLockedOut || !authStore.lockoutLastActivityTime) {
            return;
        }

        pauseLockoutInterval();

        // open lockout snackbar
        lockOutCountDown.value = 30;
        VueCookies.set('lockOutCountDown', 30);

        // count down to auto-lock
        resumeLockoutCountdown();
    };

    const pauseLockout = () => {
        pauseLockoutInterval();
        pauseLockoutCountdown();
    };

    const resumeLockout = () => {
        resetLockoutActivity();
    };

    const executeLockout = () => {
        // if not logged in do nothing
        if (!authStore.isAuthenticated || authStore.isLockedOut || !authStore.lockoutLastActivityTime) {
            return;
        }
        // navigate to lockout page
        router.push({ name: 'lockout' });
    };

    watch(
        () => authStore.token,
        (newToken, oldToken) => {
            // If no token or on login/lockout pages, pause lockout
            if (!newToken) {
                pauseLockout();
                stopTokenRefreshTimer();
                return;
            }

            if (!oldToken) {
                resumeLockout();
            }
            startTokenRefreshTimer();
        },
        { immediate: true },
    );

    return {
        resetLockoutActivity,
        lockOutCountDown,
        pauseLockout,
        resumeLockout,
        stopTokenRefreshTimer,
    };
}
