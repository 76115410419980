import merge from 'lodash/merge';
import BackboneElement from './BackboneElement';

/**
 * @property {String} linkId // R!  Pointer to specific item from Questionnaire
 * @property {String} definition // ElementDefinition - details for the item
 * @property {String} text // Name for group or question text
 * @property {QuestionnaireResponseItemAnswer} answer
 * @property {QuestionnaireResponseItem} item // Nested questionnaire response items
 */
export default class QuestionnaireResponseVideraNote extends BackboneElement {
    static __className = 'QuestionnaireResponseVideraNote';

    __objectStructure = {
        note: String,
        createdAt: String,
    };

    constructor(constructJson, className = 'QuestionnaireResponseItem') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
