import DomainResource from './DomainResource';
import EpisodeOfCareStatusHistory from './EpisodeOfCareStatusHistory';
import EpisodeOfCareDiagnosis from './EpisodeOfCareDiagnosis';
import CodeableConcept from './types/CodeableConcept';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import Period from './types/Period';
import merge from 'lodash/merge';
import EpisodeOfCareDischargeRationale from '@/fhirworks/EpisodeOfCareDischargeRational';
import { STATUS_DISPLAY, UI_TO_ELIGIBILITY_STATUS } from '@/common/episodeStatusConfig';

const episodeOfCareStatusValues = {
    active: 'active',
    cancelled: 'cancelled',
    enteredInError: 'entered-in-error',
    finished: 'finished',
    onhold: 'onhold',
    planned: 'planned',
    waitlist: 'waitlist',
};

const episodeOfCareStatusDisplayValues = {
    active: 'Active',
    cancelled: 'Cancelled',
    'entered-in-error': 'Entered-in-error',
    onhold: 'Onhold',
    planned: 'Planned',
    waitlist: 'waitlist',
};

export default class EpisodeOfCare extends DomainResource {
    static __className = 'EpisodeOfCare';

    __objectStructure = {
        identifier: [Identifier],
        status: String, // planned | waitlist | active | onhold | finished | cancelled | entered-in-error
        statusHistory: [EpisodeOfCareStatusHistory],
        type: [CodeableConcept],
        diagnosis: [EpisodeOfCareDiagnosis],
        patient: Reference,
        managingOrganization: Reference,
        period: Period,
        referralRequest: [Reference],
        careManager: Reference,
        team: [Reference],
        account: [Reference],
        /**
         * First Class extensions
         */
        originalComplaint: String,
        estPeriod: Period,
        placementStatus: String,
        eligibilityStatus: String,
        statusReason: String,
        dischargeRationale: EpisodeOfCareDischargeRationale,
        externalReferral: Object,
        holdPeriod: Period,
        holdRationale: String,
    };

    __objectDefaults = {
        patient: new Reference(),
        period: new Period(),
        estPeriod: new Period(),
    };

    constructor(constructJson, className = 'EpisodeOfCare') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }

    // *************** Custom Getters/Setters ***************

    get bnEocStatus() {
        // First check for special eligibility statuses
        if (this?.eligibilityStatus === 'TBD') return 'TBD';
        if (this?.eligibilityStatus === 'NotEligible') return 'NotEligible';

        // If no special eligibility status, use the FHIR status
        return this?.status || 'planned';
    }

    get bnEocStatusDisplay() {
        // First check for special eligibility statuses
        if (this?.eligibilityStatus === 'TBD') {
            return UI_TO_ELIGIBILITY_STATUS[STATUS_DISPLAY.ELIGIBILITY_NOT_DETERMINED];
        }
        if (this?.eligibilityStatus === 'NotEligible') {
            return UI_TO_ELIGIBILITY_STATUS[STATUS_DISPLAY.NOT_ELIGIBLE];
        }

        // If no special eligibility status, use the FHIR status
        return this?.status || 'planned';
    }
}

export { episodeOfCareStatusValues, episodeOfCareStatusDisplayValues, EpisodeOfCare };
