import router from '@/router';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { Network } from '@capacitor/network';
import { $log } from '@/common/api/log.service';

export async function bnHttpErrorHandler(err, timeout = 25000) {
    // Add guard against recursive error handling
    if (err?.config?.url?.includes('/log')) {
        // Skip error handling for logging endpoints to prevent infinite loops
        return Promise.reject(err);
    }

    const authStore = useAuthStore();
    let notifications = [];
    console.error(err);

    let status;
    try {
        status = await Network?.getStatus();
    } catch {
        /* empty */
    }

    if (status && !status.connected && status.connectionType === 'none') {
        notifications.push({
            message: 'Please check your internet connection',
            color: 'error',
        });
    } else if (err?.message?.msg === 'Network Error' || err?.message === 'Network Error') {
        notifications.push({
            message: 'Cannot reach the server, please check your internet connection. Please try again later or contact support if problem persists.',
            color: 'error',
        });
    } else if (err.message === 'timeout of ' + timeout + 'ms exceeded') {
        notifications.push({
            message: 'The server took too long to respond. You can wait a bit and try again or reload this page.  If this problem persists please contact BestNotes for support.',
            color: 'error',
        });
    } else if (err?.response?.status === 401 && ['Invalid JWT Token', 'JWT is expired', 'Expired JWT Token'].includes(err?.response?.data?.message)) {
        // Invalid token needs to log out user even if errorHandle is set to false.  So this needs to be before errorHandle check
        await router.push({
            name: 'lockout',
            params: { lockoutDescription: 'Your screen has been locked since logging in on another device.  Only 1 browser can be logged in at a time.' },
        });
    } else if (err?.config && Object.prototype.hasOwnProperty.call(err.config, 'errorHandle') && err.config.errorHandle === false) {
        return Promise.reject(err);
    } else if (!err.response) {
        // network error (wasn't able to connect to server)
        notifications.push({
            message: 'There was a problem with the server.  Please try again.',
            color: 'error',
        });
    } else if ((err.response.status === 401 || err.response.status === 403) && authStore.isAuthenticated) {
        notifications.push({
            message: 'You do not have permission to view this content.',
            color: 'error',
        });
    } else if (err.response.data.errors && err.response.data.errors.length) {
        err.response.data.errors.forEach((err) => {
            notifications.push({
                message: err.userMessage || err.message,
                color: 'error',
            });
        });
    } else if (err.response.status >= 500) {
        notifications.push({
            message: 'There was a problem with the server.  Please try again.',
            color: 'error',
        });
    } else if (err.response.status >= 400) {
        notifications.push({
            message: 'Bad request. Please try again.  If problem persists log off then try again.',
            color: 'error',
        });
    }

    const notificationStore = useNotificationStore();

    notificationStore.add(notifications);

    // Modify the logging section to include additional safety checks
    try {
        if (!err?.config?.skipLogging) {
            // Log the error with LogService
            let errorSummary = 'HTTP Request Failed';
            let errorDescription = err.message;
            let errorTrace = '';

            // Build detailed error trace
            if (err.response) {
                // Server responded with error
                errorTrace = `Status: ${err.response.status}\n`;
                errorTrace += `Status Text: ${err.response.statusText}\n`;
                errorTrace += `URL: ${err.response.config?.url}\n`;
                errorTrace += `Method: ${err.response.config?.method}\n`;
                errorTrace += `Headers: ${JSON.stringify(err.response.headers, null, 2)}\n`;
                errorTrace += `Response Data: ${JSON.stringify(err.response.data, null, 2)}`;
            } else if (err.request) {
                // Request made but no response
                errorTrace = `Request made but no response received\n`;
                errorTrace += `URL: ${err.request.config?.url}\n`;
                errorTrace += `Method: ${err.request.config?.method}`;
            } else {
                // Error in request setup
                errorTrace = err.stack || err.toString();
            }

            // Add a flag to optionally skip logging
            $log.error(errorSummary, errorDescription, errorTrace);
        }
    } catch (loggingError) {
        // If logging fails, just console.error and continue
        console.error('Failed to log error:', loggingError);
    }

    return Promise.reject(err);
}
